
// Search function
function search() {
    let input = document.getElementById('searcharea').value;
    let searchContainer = document.querySelector('.search-results');
    if (input.length < 3) {
        searchContainer.style.display = 'none';
        return false;
    }
    let searchMarket = false;
    //check if input has data-search-type="market"
    if(document.getElementById('searcharea').getAttribute('data-search-type') == 'market') {
        searchMarket = true;
    }
    // make request to the server
    api.post('/search', {keyword: input, searchMarket: searchMarket}).then(data => {
        //response body has products and merhants
        let products = data.data.products;
        let merchants = data.data.merchants;

        if (!products && !merchants) {
            searchContainer.style.display = 'none';
        }

        searchContainer.innerHTML = '';
        // display products
        if(products) {
            products.map(product => {
                let productDiv = document.createElement('div');
                productDiv.className = 'product';
                productDiv.innerHTML = `
                <div class="search-result">
                  <a href="${product.merchant.type == 1 ? 'restaurant/'+product.merchant.slug : 'yolda-geliyo-market' }#item-${product.id}" data-type="search-result">
                    <div class="search-result-img">
                        <img src="${product.thumbnail}" alt="food1">
                    </div>
                    <div class="search-result-content">
                        <div class="search-information">
                            <h6>${product.merchant.name}</h6>
                            <p>${product.name}</p>
                        </div>
                        <div class="search-price">
                            <h6>₺${product.price}</h6>
                            <p class="badge bg-${product.merchant.status ? 'success' : 'danger'}">${product.merchant.status ? 'Açık' : 'Kapalı'}</p>
                        </div>
                    </div>
                    </a>
                </div>
            `;
                searchContainer.appendChild(productDiv);
            });
        }

        // display merchants
        if(merchants) {
            merchants.map(merchant => {
                let merchantDiv = document.createElement('div');
                merchantDiv.className = 'merchant';
                merchantDiv.innerHTML = `
                <div class="search-result">
                  <a href="${merchant.type == 1 ? 'restaurant/'+merchant.slug : 'yolda-geliyo-market' }">
                    <div class="search-result-img">
                        <img src="${merchant.thumbnail}" alt="food1">
                    </div>
                    <div class="search-result-content flex-column align-items-start">
                        <h6>${merchant.name}</h6>
                        <div class="d-flex gap-3 align-items-center">
                            <p class="badge bg-${merchant.status ? 'success' : 'danger'}">${merchant.status ? 'Açık' : 'Kapalı'}</p>
                            <p>${merchant.delivery_fees > 0 ? '₺'+merchant.delivery_fees : 'Ücretsiz Teslimat'}</p>
                            <p>₺${merchant.minimum_purchase_amount} Min.</p>
                        </div>
                    </div>
                    </a>
                </div>
            `;
                searchContainer.appendChild(merchantDiv);
            });
        }

        if(products === false && merchants === false) {
            let noResult = document.createElement('div');
            noResult.className = 'no-result';
            noResult.innerHTML = `
                <h6>Aradığınız sonuç bulunamadı</h6>
            `;
            searchContainer.appendChild(noResult);
        }

        searchContainer.style.display = 'flex';

    });
}

//listen to keyup event and call search function with debounce
let searchInput = document.getElementById('searcharea');
let debounceTimeout;
searchInput.addEventListener('keyup', () => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(search, 500);
});
